import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Blog from './pages/Blog';
import Header from './components/Header';
import Footer from './components/Footer';
import BackgroundVideo from './components/BackgroundVideo'


function App() {
  return (
    <div className='App'>
      <Header />
      <BackgroundVideo />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
