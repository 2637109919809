import React, { Component } from 'react';
import Header from '../components/Header';
import BackgroundVideo from '../components/BackgroundVideo'

class Blog extends Component {
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default Blog;