import React, { Component } from 'react';
import './Footer.css'

class Footer extends Component {
    render() {
        return (
            <div className='Footer-content'>
                Copyright © 2023 Long Hong Nguy.
            </div>
        );
    }
}

export default Footer;