import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cerImg from "../assets/img/Hanoi-University-of-Mining-and-Geology-1.png"
import Background from "../assets/img/f3bd45f6d915b011bc29f6661a73bac9.jpg"
import './Body.css'



function Body() {
    return (
        <div>
            <Container className="background">
                <h1 className="title">Something about me!</h1>
                <img className="spiderman" src={Background} alt="background" />
                <Row>
                    <Col sm={6} className="p-3">
                        <div>
                            <p>
                                I'm currently a final-year student at Hanoi University of Mining and Geology. My major is Computer Science. I always try new technologies, and new frameworks to apply to my projects.
                            </p>
                            <p>
                                In 2022, I was selected to be a GDSC Lead, I take the responsibility of inspiring students, helping them connect, learning Google technologies, and building solution to solve the problem of local.
                            </p>
                            <div className="cer-wrap">
                                <img className="cer" src={cerImg} alt="gdsc" />
                            </div>
                            <p className="cer-t">This is the most precious experience in my college life.</p>

                        </div>
                    </Col>
                    <Col sm={6} className="p-3">
                        <div>
                            <h5>Infomation</h5>
                            <ul>
                                <li>
                                    President/Leader at <a href="https://gdsc.community.dev/hanoi-university-of-mining-and-geology/">Google Developer Student Club - Hanoi University of Mining and Geology</a>
                                </li>
                                <li>
                                    Administrator at <a href="https://www.facebook.com/humgit">Infomation Technology Club HUMG</a>
                                </li>
                                <li>
                                    Computer science major student at <a href="https://www.facebook.com/humgit">Hanoi University of Mining and Geology</a>
                                </li>

                            </ul>

                            <h5>Prizes and Certificate</h5>
                            <ul>
                                <li>Completed the 'Lead a Google Developer Student Club (GDSC)' pathway</li>
                                <li>Top 30 Technology Solutions in the Busitech Bootcamp Competition</li>
                                <li>Certificate of ASEAN Students Contest on Information Security</li>
                                <li>Second Prize Winner of SV.STARTUP HUMG 2022</li>
                                <li>Second Prize Winner of the Junior Science Research Committee HUMG</li>
                                <li>Second Prize Winner of Olympic English HUMG</li>
                                <li>Scholarship of HUMG</li>
                                <li>Scored 957/1000 points (Word) in the Microsoft Office Specialist (MOS) exam</li>
                            </ul>

                            <h5>Skills</h5>
                            <ul>

                                <li>
                                    Leadership
                                </li>
                                <li>
                                    English: Listening, writing and reading comprehension
                                </li>
                                <li>
                                    Power automate
                                </li>
                                <li>
                                    Web, application and game development
                                </li>

                                <li>
                                    AI, Machine learning, Deeplearning
                                </li>
                                <li>
                                    IOT
                                </li>
                                <li>
                                    Enthical hacking
                                </li>
                            </ul>


                            <h5>Projects</h5>
                            <ul>
                                <li>
                                    <a href="https://github.com/Google-Developer-Student-Club-HUMG/classguard">ClassGuard</a> : An IoT-based Real-time School Violence Warning System.
                                </li>
                            </ul>

                            <h5>Frameworks</h5>
                            <ul>
                                <li>
                                    ASP .NET Web application C#
                                </li>
                                <li>
                                    Unity
                                </li>
                                <li>
                                    Flutter
                                </li>
                            </ul>

                            <h5>Career Goals</h5>
                            <ul>
                                <li>
                                    Fullstack developer
                                </li>
                                <li>
                                    Cyber Security Specialist
                                </li>
                            </ul>

                            <h5>Experience</h5>
                            <ul>
                                <li>
                                    FrontEnd Developer Intern at <a href="https://p5.com.vn/">P5.com.vn</a> (3 months)
                                </li>
                            </ul>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Body;