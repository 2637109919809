import React, { Component } from 'react';
import myVideo from '../assets/videos/background-video.mp4';
import "./BackgroundVideo.css"

class BackgroundVideo extends Component {
    render() {
        return (
            <div>
                <video autoPlay muted loop id="myvideo">
                    <source src={myVideo} type="video/mp4"/>
                </video>
            </div>
        );
    }
}

export default BackgroundVideo;