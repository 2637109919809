import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <Container>
      <Navbar expand="lg" variant="dark">
        <Navbar.Brand as={Link} to="/">Long Hong Nguy</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://github.com/nguyhonglong">Github</Nav.Link>
            <Nav.Link as={Link} to="/blog">Blog</Nav.Link>
            <NavDropdown title="Contact" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://www.facebook.com/long.hong.nguy">Facebook</NavDropdown.Item>
              <NavDropdown.Item href="https://www.linkedin.com/in/newking2002/">
                Linkedln
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                Gmail: nguyhonglong2002@gmail.com
              </NavDropdown.Item>
              <NavDropdown.Item>
                Phone Number: +84974758821
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default Header;