import React, { Component } from 'react';
import BackgroundVideo from '../components/BackgroundVideo';
import Body from '../components/Body';
import Header from '../components/Header';

class HomePage extends Component {
    render() {
        return (
            <div>
                <Body />
            </div>
        );
    }
}

export default HomePage;